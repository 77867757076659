/* eslint-disable max-len */

'use strict';

define('vbtu/mocks/mockAction',['vb/action/action', 'vb/private/stateManagement/stateUtils'],
  (Action, StateUtils) => {
    /**
     * This action is used to mock an action. It simply returns the mocked outcome specified by the test.
     * The mock action is loaded by replacing the action's module property with 'vbtu/mocks/mockAction' in the
     * action's definition.
     */
    class MockAction extends Action {
      start(parameters) {
        return Promise.resolve()
          .then(() => this.perform(parameters));
      }

      perform(parameters) {
        // the mocked outcome is passed in using __MOCK__ parameter
        // eslint-disable-next-line no-underscore-dangle
        const mock = parameters.__MOCK__;

        if (mock.context) {
          this.initVariablesContext(mock.context);
        }

        const name = mock.outcome || 'success';

        // For JS chain, the mock result is specified via mock.return whereas for JSON chain, it's mock.result
        const result = mock.result !== undefined ? mock.result : mock.return;

        return { name, result };
      }

      setContext(scopes) {
        this.scopes = scopes;
      }

      /**
       * Initialize variables using the given context specified by the test.
       *
       * @param context variable context specified by the test
       */
      initVariablesContext(context) {
        Object.keys(context).forEach((scopeName) => {
          const scopeContext = context[scopeName];

          if (scopeContext && scopeContext.variables) {
            Object.keys(scopeContext.variables).forEach((variableName) => {
              const valueExpr = scopeContext.variables[variableName];

              // evaluate the source value expression
              let value = StateUtils.getValueOrExpression(valueExpr, this.scopes);
              value = (typeof value === 'function') ? value() : value;

              // assign the value to the variable
              this.scopes[scopeName].variables[variableName] = value;
            });
          }
        });
      }
    }

    return MockAction;
  });

