'use strict';

define('vbtu/mocks/mockSecurityProvider',['vb/private/constants', 'vb/types/securityProvider'], (Constants, SecurityProvider) => {
  /**
   * Mock security provider which will be injected by the action chain tester into the application whose chains are
   * being tested.
   */
  class MockSecurityProvider extends SecurityProvider {
    initialize(config) {
      return Promise.resolve().then(() => {
        this.userInfo = Object.assign({
          userId: Constants.User.TESTER,
          username: Constants.User.TESTER,
          longId: Constants.User.TESTER,
          fullName: Constants.User.TESTER,
          email: Constants.User.TESTER,
          roles: [],
          permissions: [],
          isAdmin: false,
          isAuthenticated: true,
        }, config.userOverride);
      });
    }

    /**
     * Return an empty list of plugins.
     *
     * @returns {Promise<*[]>}
     */
    // eslint-disable-next-line class-methods-use-this
    getServiceWorkerPlugins() {
      return Promise.resolve([]);
    }
  }

  return MockSecurityProvider;
});

