'use strict';

define('vbtu/actionStubs/navigateActionStub',['vbtu/actionStubs/actionStub'], (ActionStub) => {
  /**
   * Action stub for NavigateAction.
   */
  class NavigateActionStub extends ActionStub {
    // eslint-disable-next-line class-methods-use-this
    perform(parameters) {
      return Promise.resolve(ActionStub.createSuccessOutcome({ navigated: true }, parameters));
    }
  }

  return NavigateActionStub;
});

