'use strict';

define('vbtu/actionStubs/actionStub',['vb/action/action'], (Action) => {
  /**
   */
  class ActionStub extends Action {
    start(parameters) {
      return Promise.resolve()
        .then(() => this.perform(parameters));
    }

    perform(parameters) {
      return ActionStub.createSuccessOutcome(undefined, parameters);
    }

    /**
     * Possible action outcome name
     */
    static get Outcome() {
      return {
        SUCCESS: 'success',
        FAILURE: 'failure',
        TRUE: 'true',
        FALSE: 'false',
      };
    }

    /**
     * Utility method to create an outcome with the name and payload.
     *
     * @param name The name of the outcome - will determine what action occurs next
     * @param result The optional payload for the result
     */
    static createOutcome(name, result) {
      return { name, result };
    }

    /**
     * Utility method to create a 'success' outcome.
     *
     * @param result The optional payload for the result
     */
    static createSuccessOutcome(result, inputs) {
      return { name: this.Outcome.SUCCESS, result, inputs };
    }
  }

  return ActionStub;
});

