'use strict';

// use by requirejs to combine vb test utils classes into a single file
(function () {
  const config = {
    map: {
      '*': {
        main: 'vbtu/main',
      },
    },
  };

  requirejs.config(config);
}());

define("vbtu/main", function(){});

