/* eslint-disable max-len */

'use strict';

define('vbtu/mocks/mockActionChain',['vb/private/action/actionChain'],
  (ActionChain) => {
    /**
     * Mock action chain that is used to override default action chain behaviors.
     */
    class MockActionChain extends ActionChain {
      /**
       *  Override addContextToAction so we can call setContext on the mockAction.
       */
      addContextToAction(actionType, actionDef, action, availableContexts) {
        if (actionType === 'vbtu/mocks/mockAction') {
          action.setContext(availableContexts);
        } else {
          super.addContextToAction(actionType, actionDef, action, availableContexts);
        }
      }
    }

    return MockActionChain;
  });

