'use strict';

define('vbtu/actionStubs/loginActionStub',['vbtu/actionStubs/actionStub'], (ActionStub) => {
  /**
   */
  class LoginActionStub extends ActionStub {

    perform(parameters) {
      return Promise.resolve(ActionStub.createSuccessOutcome(true, parameters));
    }
  }

  return LoginActionStub;
});

