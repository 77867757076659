/* eslint-disable max-len,global-require */

'use strict';

define('vbtu/testUtils',['chai', 'vbtu/actionChainTester'], (chai, ActionChainTester) => {
  /**
   * This utility class is used to run unit tests against action chains. The script containing this class is
   * available on VB CDN as vb-test-utils.js. After loading vb-test-utils.js script, you can loading the TestUtils
   * class by using the 'vb/testUtils' module id.
   *
   * See tests/testUtils/testUtilsSpec for an example on how to load and use this class to run a unit test against
   * an action chain.
   *
   * In addition, it is used by the VB webdriverjs test utils to test assertions against variables in a VB
   * application.
   */
  class TestUtils {
    /**
     * This method is used to set up window.vbInitConfig required to bootstrap the runtime. Here is an example
     * of config:
     *
     * {
     *   // JET CDN path and version
     *   JET_CDN_PATH: 'https://static.oracle.com/cdn/jet/',
     *   JET_CDN_VERSION: 'v6.0.0',
     *
     *   // location of the visual-runtime.js including the version
     *   VB_CDN_PATH: 'https://static.oracle.com/cdn/vb/v19.1.1.3/',
     *
     *   // base url for the application up to version and before webApps
     *   APP_BASE_URL: 'https://vbmasterdev.com/rt/MyApp/1.0/',
     *
     *   // if true, load visual-runtime-debug.js instead
     *   DEBUG: true,
     *
     *   // specify DT runtime environment class override
     *   RUNTIME_ENV_CLASS: null,
     * }
     *
     * @param config the config object
     */
    static init(config = {}) {
      window.vbInitConfig = window.vbInitConfig || {};

      // merge the given config into window.vbInitConfig
      Object.assign(window.vbInitConfig, config);
    }

    /**
     * Run the action chain specified by path and chainId with the given context and mock. The path is the
     * physical local of the container relative to the APP_BASE_URL specified in the init config.
     *
     * For example:
     *
     * To test an action in main-page, use the following path:
     * 'webApps/actionChainTestApp/flows/main/pages/main-page'.
     *
     * To test an action in main-flow, use the following path:
     * 'webApps/actionChainTestApp/flows/main-flow'.
     *
     * To test an action in app-flow.json, use the following path:
     * 'webApps/actionChainTestApp/app-flow'.
     *
     * The context is used to set up the initial variable state before the action chain is executed. It lets you
     * specify values for variables in $application, $flow, $page and $chain. The values can be any valid expressions
     * supported by the runtime. Here's an example:
     *
     * {
     *   $application: {
     *     variables: {
     *       appVar1: '{{  some expression }}'
     *     },
     *     constants: {
     *       appConst1: 'FOO'
     *     }
     *   },
     *   $flow: {
     *     variables: {
     *       flowVar1: 'foo'
     *     },
     *     constants: {
     *       flowConst1: 'FOO'
     *     }
     *   },
     *   $page: {
     *     variables: {
     *       pageVar1: 'bar'
     *     },
     *     constants: {
     *       pageConst1: 'FOO'
     *     }
     *   },
     *   $layout: {
     *     variables: {
     *       layoutVar1: 'bar'
     *     },
     *     constants: {
     *       layoutConst1: 'FOO'
     *     }
     *   },
     *   $chain: {
     *     variables: {
     *       chainVar1: 'foobar'
     *     },
     *     constants: {
     *       chainConst1: 'FOO'
     *     }
     *   },
     *   $variables: {
     *     chainVar2: 'barfoo'
     *   },
     *   $constants: {
     *     chainConst1: 'BAR'
     *   }
     * }
     *
     * The mock is used to provide mock input parameters and outcome for actions in the action chain. Note that
     * the mock parameters can be any valid expressions supported by the runtime. For example,
     *
     * {
     *   action1: {
     *     parameters: {
     *       foo: '{{ some expression }}'
     *     },
     *     outcome: {
     *       name: 'success',
     *       result: 'foobar'
     *     }
     *   }
     * }
     *
     * The result of this method is the entire execution state of the action chain. Here's an example:
     *
     * {
     *   $application: {
     *     variables: {
     *       appVar1: 'foo'
     *     }
     *   },
     *   $flow: {
     *     variables: {
     *       flowVar1: 'bar'
     *     }
     *   },
     *   $page: {
     *     variables: {
     *       pageVar1: 'foobar'
     *     }
     *   },
     *   $layout: {
     *     variables: {
     *       layoutVar1: 'foobar'
     *     }
     *   },
     *   $chain: {
     *     results: {
     *       action1: 'barfoo'
     *     }
     *   },
     *   $actions: {
     *     action1: {
     *       'outcome': 'success'
     *       'inputs': '....'
     *     }
     *   }
     * }
     *
     * @param path the path to the container containing the action chain
     * @param chainId the id for the chain to excute
     * @param context initial variable context before the chain is executed
     * @param mock used for mocking actions
     * @returns {Promise<Object>}
     */
    static runActionChainTest(path, chainId, context = {}, mock = {}, options = {}) {
      const tester = new ActionChainTester({
        path, chainId, context, mock, options,
      });

      return tester.runTest();
    }

    /**
     * If window.vbInitConfig.PRESERVE_LOADED_MODULES is true, the action chain tester will not
     * automatically unload all the loaded modules after each test run. This method is used to
     * force unload all the modules even if PRESERVE_LOADED_MODULES set to true.
     *
     * @returns {*}
     */
    static unloadActionChainTestModules() {
      return ActionChainTester.unloadAllModules(true);
    }

    /**
     * Sanitize the error object so it can be serialized back to the tester.
     *
     * @param error error object to sanitize
     * @returns {string}
     */
    static sanitizeError(error) {
      // Only serialize the message and the stack
      return JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)));
    }

    /**
     * The method is called by VB webdriverjs test utils to assert the given assertion in JSON string format.
     * It uses expect from chai to perform the assertion.
     *
     * @param assertionJsonStr assertion in JSON string format sent from VB webdriver test util
     * @returns {Promise}
     */
    static assert(assertionJsonStr) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line import/no-dynamic-require
        requirejs(['ojs/ojcontext', 'vb/private/stateManagement/router', 'vb/private/stateManagement/stateUtils',
          'vb/helpers/rest'],
        (ojContext, Router, StateUtils, RestHelper) => {
          // need to wait for the busy context to clear before executing the assertion
          ojContext.getPageContext().getBusyContext().whenReady().then(() => {
            try {
              const assertion = JSON.parse(assertionJsonStr);
              const { expression, extraScope } = assertion;

              Promise.resolve()
                .then(() => {
                  const scopes = Router.getCurrentPage().availableContexts;
                  // The only extra scope we currently support is the response from a REST endpoint.
                  // We may support additional scopes in the future.
                  if (extraScope) {
                    // evaluate expressions in the extra scope
                    const evaluatedScope = StateUtils.deepEval(extraScope, scopes);

                    const rest = RestHelper.get(evaluatedScope.endpointId)
                      .initConfiguration(evaluatedScope.initConfig)
                      .parameters(evaluatedScope.params)
                      .body(evaluatedScope.body);

                    return rest.fetch()
                      .then((responseWrapper) => {
                        if (responseWrapper) {
                          const { response } = responseWrapper;
                          // create $response from the response from the REST endpoint
                          const $response = {
                            body: responseWrapper.body,
                            ok: response.ok,
                            status: response.status,
                            statusText: response.statusText,
                            headers: {},
                          };

                          // map the response headers to a plain object
                          for (const entry of response.headers.entries()) {
                            if (entry && entry.length >= 2) {
                              // lower case header names so we can assert the headers in a canonical way
                              $response.headers[entry[0].toLowerCase()] = entry[1];
                            }
                          }

                          // create scopes containing $response plus the available scopes from the page
                          return Object.assign({ $response }, scopes);
                        }
                        return scopes;
                      });
                  }
                  return scopes;
                })
                .then((allScopes) => {
                  const value = StateUtils.deepEval(expression, allScopes);
                  // Do not truncate the chai message. This is useful for large object diff
                  // eslint-disable-next-line no-param-reassign
                  chai.config.truncateThreshold = 0;

                  let expect = chai.expect(value);

                  assertion.chain.forEach((desc) => {
                    if (typeof desc === 'string') {
                      expect = expect[desc];
                    } else {
                      const name = desc.name;
                      const args = desc.args;
                      let evaluatedArgs = [];

                      if (name === 'match') {
                        args[0] = new RegExp(args[0]);
                      }

                      if (args) {
                        evaluatedArgs = args.map((arg) => StateUtils.deepEval(arg, allScopes));
                      }

                      expect = expect[name](...evaluatedArgs);
                    }
                  });
                  resolve();
                })
                .catch((error) => {
                  reject(TestUtils.sanitizeError(error));
                });
            } catch (error) {
              reject(TestUtils.sanitizeError(error));
            }
          });
        }, reject);
      });
    }
  }

  return TestUtils;
});

